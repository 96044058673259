import React, { useRef } from "react";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";

import ScrollView from "devextreme-react/scroll-view";
import requester from "../utils/requester";
import { DataGrid } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import {
  Column,
  HeaderFilter,
  SearchPanel,
  GroupPanel,
  Grouping,
} from "devextreme-react/data-grid";
import { toast } from "react-toastify";

const valueMap = {
  album: "photo & video",
};
export default function AlbumHistory({
  showAlbumHistory,
  setShowAlbumHistory,
  albumId,
}) {
  const dataGridRef = useRef();
  const getAlbumHistory = async () => {
    return await requester()
      .get(`/albums/history/${albumId}`)
      .then((response) => {
        return response.data;
      });
  };

  const reviewHistory = async () => {
    try {
      await requester().post(`/albums/review-history`, { ticketId: albumId });
      dataGridRef.current.instance.getDataSource().reload();
      toast.info(
        <div style={{ textAlign: "center" }}>Review processed successfully</div>
      );
    } catch (err) {
      toast.error(
        <div style={{ textAlign: "center" }}>Failed to review the changes</div>
      );
    }
  };

  const dataSource = new CustomStore({
    key: "_id",
    load: () => {
      return getAlbumHistory();
    },
  });

  return (
    <Popup
      visible={showAlbumHistory}
      onHiding={() => setShowAlbumHistory(false)}
      dragEnabled
      //   closeOnOutsideClick
      showCloseButton
      showTitle
      title="Receipt"
      container=".dx-viewport"
      width={700}
      maxWidth="90%"
      maxHeight="95%"
    >
      <Position at="center" my="center" />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: "Back",
          onClick: () => {
            setShowAlbumHistory(false);
          },
        }}
      />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: "Review",
          onClick: () => {
            reviewHistory();
          },
        }}
      />
      <ScrollView width="100%" height="100%">
        {
          <DataGrid
            ref={dataGridRef}
            dataSource={dataSource}
            showBorders={true}
            showRowLines={true}
            cellHintEnabled={true}
            wordWrapEnabled={true}
            width="100%"
            onRowPrepared={({ data, rowElement, rowType }) => {
              if (!data?.reviewedBy && rowType === "data")
                rowElement.style.backgroundColor = "#ffff006b";
            }}
          >
            <SearchPanel visible={true} all />
            <GroupPanel visible={true} emptyPanelText="Drag to group" />
            <Grouping autoExpandAll={true} />
            <HeaderFilter visible={true} allowSearch />

            <Column
              dataField="user.name"
              alignment={"center"}
              caption="Updated By"
            />
            <Column
              dataField="reviewedBy"
              alignment={"center"}
              caption="Reviewed By"
            />

            <Column dataField="fieldName" alignment={"center"} />
            <Column
              dataField="oldValue"
              alignment={"center"}
              calculateCellValue={(rowData) =>
                valueMap[rowData.oldValue] || rowData.oldValue
              }
            />
            <Column
              dataField="newValue"
              alignment={"center"}
              calculateCellValue={(rowData) =>
                valueMap[rowData.newValue] || rowData.newValue
              }
            />
            <Column dataField="version" alignment={"center"} grouped={true} />

            <Column
              dataField="createdAt"
              dataType="date"
              alignment={"center"}
              sortOrder={"asc"}
              allowFiltering={false}
            />
          </DataGrid>
        }
      </ScrollView>
    </Popup>
  );
}
