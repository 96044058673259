import React from "react";
import "./styles.scss";
import moment from "moment";
import logo from "../../assets/LOGO.png";
import aquariumLogo from "../../assets/aquarium-logo.jpg";
import paidStamp from "../../assets/paid-stamp.svg";
import QRCode from "qrcode.react";
import { useUserIdentity } from "../../hooks/useUserIdentity";
import { getNearestDate } from "../../utils/helpers";

const getReceivingNote = ({ isAquariumTicket, selectedRow }) => {
  const showReceivingNote =
    (selectedRow?.imagesPrintCount && selectedRow?.imagesPrintSize) ||
    selectedRow?.albumSize ||
    selectedRow?.remaining ||
    isAquariumTicket;
  if (selectedRow.receivingTime && showReceivingNote) {
    const receivingTime = moment(selectedRow?.receivingTime)
      .utc()
      .format("HH:mm");
    const receivingDate = moment(selectedRow?.receivingDate).format(
      "DD/MM/YYYY"
    );
    if (isAquariumTicket)
      switch (selectedRow?.receivingOption) {
        case "productDelivery":
          return `Please ask in your reception at ${receivingDate} ${receivingTime} to receive your order`;
        case "restDelivery":
          return `Our delivery will meet you at at ${receivingDate} ${receivingTime} outside the hotel to receive the rest of money`;
        default:
          return "";
      }
    else {
      return `${
        selectedRow?.receivingOption === "aquaCenter"
          ? `Please ask in your Aqua Center at ${moment(
              selectedRow?.receivingDate
            ).format("DD/MM/YYYY")} 13:00 to receive your order`
          : moment(selectedRow?.receivingTime).format("HH:mm") === "23:45"
          ? `Please ask in your reception at ${moment(
              selectedRow?.receivingDate
            ).format("DD/MM/YYYY")} 23:45 to receive your order`
          : `We will meet you at ${moment(selectedRow?.receivingDate).format(
              "DD/MM/YYYY"
            )} ${moment(selectedRow?.receivingTime)
              .utc()
              .format("HH:mm")} outside the hotel`
      }`;
    }
  }
  return "";
};

const getFolderUnlockTime = ({ selectedRow }) => {
  if (
    selectedRow.receivingOption === "restDelivery" ||
    !selectedRow.deliveryRemaining ||
    !selectedRow.usbRemaining ||
    !selectedRow.rest
  )
    return getNearestDate(
      moment(selectedRow?.createdAt),
      moment(selectedRow?.receivingDate).set({
        hour: moment(selectedRow?.receivingTime).utc().hour(),
        minutes: moment(selectedRow?.receivingTime).utc().minutes(),
      })
    );

  return moment(selectedRow?.createdAt);
};

function TicketReceipt({ selectedRow }) {
  const { isPhotographer, isAquariumSales } = useUserIdentity();
  const isAquariumTicket = selectedRow.tripType === "grand-aquarium";

  const formattedValues = {
    ...selectedRow,
    createdAt: moment(selectedRow?.createdAt).format("DD/MM/YYYY"),
    photoDeliveryOption: `${selectedRow?.dvd ? "DVD" : "Online"} ${
      selectedRow?.serviceType === "album"
        ? "video & photos"
        : selectedRow?.serviceType?.toUpperCase?.()
    }`,
    description: `${
      selectedRow?.albumSize
        ? `ALBUM ${selectedRow.albumSize.toUpperCase?.()}`
        : ""
    } ${selectedRow?.imagesPrintCount || ""} ${
      selectedRow?.imagesPrintSize || ""
    }`.replace("  ", ""),
    folderUnlockTime: getFolderUnlockTime({ selectedRow }),
    receivingNote: getReceivingNote({ isAquariumTicket, selectedRow }),
    albumLink: `${window.location.origin}/${selectedRow?.tripType}/${selectedRow?.code}`,
    isEdited: !moment(selectedRow?.createdAt).isSame(
      moment(selectedRow?.updatedAt),
      "minute"
    ),
    contactNumbers: isAquariumTicket
      ? ["+201026700067", "+201111156852"]
      : ["+201111156852", "+201111124237"],
  };

  return (
    <div className="your-code-001234" data-aquarium-ticket={isAquariumTicket}>
      <div className="header-parent">
        <div className="header">
          <div className="fimtosoft-parent">
            <b className="fimtosoft">FimtoSoft</b>
            <div className="frame-parent">
              <div className="invoice-parent">
                <div className="invoice">Invoice:</div>
                <b className="invoice-serial">
                  {formattedValues.ticketId}{" "}
                  <span style={{ color: "#ff8900" }}>
                    {formattedValues.createdBy}
                  </span>
                </b>
              </div>
              {(formattedValues.partner || formattedValues.boat) && (
                <div className="invoice-parent">
                  <div className="invoice">Ref:</div>
                  <b className="eg-10001-badr">
                    {[formattedValues.partner, formattedValues.boat]
                      .filter(Boolean)
                      .join(" - ")}
                  </b>
                </div>
              )}
              <div className="invoice-parent">
                <div className="invoice">Date:</div>
                <b className="eg-10001-badr">{formattedValues.createdAt}</b>
              </div>
            </div>
          </div>
          {isAquariumTicket && (
            <div className="aquarium-logo">
              <img src={aquariumLogo} />
            </div>
          )}
          <div className="logo" data-aquarium-ticket-logo={isAquariumTicket}>
            <img src={logo} />
          </div>
        </div>
        <span
          className="ellipse-parent"
          data-aquarium-ticket={isAquariumTicket}
        />
        <div className="task-details">
          {formattedValues?.isEdited && <div className="edit-stamp">تعديل</div>}
          <div className="table-header">
            <b className="b">#</b>
            <div className="item-description-optional-container">
              <b>Item</b>
            </div>
            <div className="total-usd">
              <b>Total</b>
            </div>
            <b className="paid">Paid</b>
            <b className="rest">Rest</b>
          </div>
          {formattedValues?.products?.map((prod) => (
            <div className="task-name">
              <div className="div">{prod.quantity}x</div>
              <div className="task">
                <div className="online-video">{prod.name}</div>
              </div>
              <div className="div1">
                {formattedValues.currency}
                {prod.price}
              </div>
            </div>
          ))}
          <div
            className="task-name"
            style={isAquariumTicket ? { color: "#ff8900" } : {}}
          >
            <div className="div">{isAquariumTicket ? "" : "x"}</div>
            <div className="task">
              <div className="online-video">
                {isAquariumTicket
                  ? `Total: ${formattedValues?.products?.length} Products`
                  : formattedValues.photoDeliveryOption}
              </div>
              {formattedValues.description && (
                <div className="write-a-description">
                  {formattedValues.description}
                </div>
              )}
            </div>
            <div className="div1">
              {formattedValues.currency}
              {formattedValues.total}
            </div>
            <div className="div1">
              {formattedValues.currency}
              {formattedValues.paid}
            </div>
            <div className="div3">
              {formattedValues.currency}
              {formattedValues.remaining}
            </div>
          </div>
          {formattedValues.usbTotal && (
            <div className="task-name">
              <div className="div">x</div>
              <div className="task">
                <div className="online-video">USB</div>
              </div>
              <div className="div1">
                {formattedValues.usbCurrency}
                {formattedValues.usbTotal}
              </div>
              <div className="div1">
                {formattedValues.usbCurrency}
                {formattedValues.usbPaid}
              </div>
              <div className="div3">
                {formattedValues.usbCurrency}
                {formattedValues.usbRemaining}
              </div>
            </div>
          )}
          {formattedValues.deliveryTotal && (
            <div
              className="task-name"
              style={isAquariumTicket ? { color: "#ff8900" } : {}}
            >
              <div className="div">{isAquariumTicket ? "" : "x"}</div>
              <div className="task">
                <div className="online-video">DELIVERY</div>
              </div>
              <div className="div1">
                {formattedValues.deliveryCurrency}
                {formattedValues.deliveryTotal}
              </div>
              <div className="div1">
                {formattedValues.deliveryCurrency}
                {formattedValues.deliveryPaid}
              </div>
              <div className="div3">
                {formattedValues.deliveryCurrency}
                {formattedValues.deliveryRemaining}
              </div>
            </div>
          )}
        </div>
        {(!isPhotographer || !isAquariumSales) && (
          <>
            <div className="footer">
              <div className="client-details-parent">
                <b className="client-details">Client Details</b>
                <div className="frame-group">
                  <div className="name">Name</div>
                  <div className="john-smith">{formattedValues.clientName}</div>
                  <div className="name">Mobile Number</div>
                  <div className="john-smith">{formattedValues.phone}</div>
                  {formattedValues.email && (
                    <>
                      {" "}
                      <div className="name">Email</div>
                      <div className="john-smith">{formattedValues.email}</div>
                    </>
                  )}
                  <div className="name">Hotel Name</div>
                  <div className="john-smith">{formattedValues.hotelName}</div>
                  <div className="name">Room No.</div>
                  <div className="john-smith">{formattedValues.roomNo}</div>
                  {formattedValues.guide && (
                    <>
                      <div className="name">Guide</div>
                      <div className="john-smith">{formattedValues.guide}</div>
                    </>
                  )}
                  {formattedValues.tourleader && (
                    <>
                      <div className="name">Tourleader</div>
                      <div className="john-smith">
                        {formattedValues.tourleader}
                      </div>
                    </>
                  )}

                  {formattedValues.notes && (
                    <>
                      <div className="name">Notes</div>
                      <div className="john-smith">{formattedValues.notes}</div>
                    </>
                  )}
                  {!formattedValues.isLocked && (
                    <img src={paidStamp} className="paid-stamp" />
                  )}
                  {formattedValues.deliveryToday && (
                    <div class="today-delivery">Today's Delivery</div>
                  )}
                </div>
              </div>
            </div>
            <span
              className="ellipse-parent"
              data-aquarium-ticket={isAquariumTicket}
            />
            <div className="footer-parent">
              <div className="footer1">
                <div className="frame-div hidden-print">
                  <div className="download-your-photo-parent">
                    <div className="download-your-photo">
                      Download Your photo:
                    </div>
                    <div className="album-link">
                      {formattedValues.albumLink}
                    </div>
                  </div>
                  <QRCode
                    width={"450"}
                    value={formattedValues.albumLink}
                    onClick={() => window.open(formattedValues.albumLink)}
                  />
                </div>
                <div className="text-container1">
                  {formattedValues.receivingNote && (
                    <div className="amount1">
                      <div className="we-will-meet">
                        {formattedValues.receivingNote}
                      </div>
                    </div>
                  )}
                  <div className="border-bottom1"></div>
                  <div className="amount2 hidden-print">
                    <div className="your-photos-will">
                      Your photos will be available at{" "}
                      {moment(formattedValues?.folderUnlockTime).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-header1">
                <div className="dont-hesitate-to">
                  Don’t Hesitate to contact us
                </div>
                <div className="fimtosoft">
                  {formattedValues.contactNumbers[0]}
                </div>
                <div className="fimtosoft">
                  {formattedValues.contactNumbers[1]}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export { TicketReceipt };
