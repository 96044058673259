import React from "react";
import styles from "./styles.module.scss";

import { ReactComponent as OurClientsTitleSVG } from "../../assets/our-clients-title.svg";
import ourClients from "../../assets/our-clients.png";

export default function Clients() {
  return (
    <div
      id="clients"
      className={styles.sectionContainer}
      style={{ padding: "3rem 15% 0" }}
    >
      <OurClientsTitleSVG width="15rem" height="5rem" />
      <img src={ourClients} />
    </div>
  );
}
