import React from "react";

import HomeHeader from "./HomeHeader";
import Services from "./Services";
import Clients from "./Clients";
import Projects from "./Projects";
import Slug from "./Slug";

export default function Home() {
  return (
    <div className="black-background">
      <HomeHeader />

      <Services />

      <Clients />

      <Projects />
      
      <Slug />
    </div>
  );
}
