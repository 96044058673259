import React, { useRef, useState } from "react";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";

import { useReactToPrint } from "react-to-print";
import ScrollView from "devextreme-react/scroll-view";
import { TicketReceipt } from "./TicketReceipt/TicketReceipt";
import { useUserIdentity } from "../hooks/useUserIdentity";
import MessagesHistory from "./MessagesHistoryPopup";
import AlbumHistory from "./AlbumHistoryPopup";

export default function ReceiptPopup({
  selectedRows,
  setSelectedRows,
  onClose,
  isAquariumTickets,
}) {
  const receiptRef = useRef();
  const { isAdmin, isSuperAdmin, isPhotographer, isDelivery } =
    useUserIdentity();
  const [showMessagesHistory, setShowMessagesHistory] = useState(false);
  const [showAlbumHistory, setShowAlbumHistory] = useState(false);
  const reactToPrintContent = React.useCallback(() => {
    return receiptRef.current;
  }, [receiptRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Receipt",
    removeAfterPrint: false,
    bodyClass: "printContainer",
  });

  const handleSmallSizePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "Receipt",
    removeAfterPrint: false,
    bodyClass: "smallPrintContainer",
  });

  const downloadPdf = (name, id) => {
    if (selectedRows?.[0]?._id) {
      const isAquariumTicket = selectedRows?.[0]?.tripType === "grand-aquarium";
      var link = document.createElement("a");
      link.download = name;
      link.href = `https://fimtosoft.herokuapp.com/${
        isAquariumTicket ? "aquarium" : "albums"
      }/download-receipt/${selectedRows?.[0]?._id}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (showMessagesHistory && selectedRows?.length === 1)
    return (
      <MessagesHistory
        showMessagesHistory={showMessagesHistory}
        setShowMessagesHistory={setShowMessagesHistory}
        albumId={selectedRows?.[0]?._id}
      />
    );

  if (showAlbumHistory && selectedRows?.length === 1)
    return (
      <AlbumHistory
        showAlbumHistory={showAlbumHistory}
        setShowAlbumHistory={setShowAlbumHistory}
        albumId={selectedRows?.[0]?._id}
      />
    );

  return (
    <Popup
      visible={!!selectedRows?.length}
      onHiding={() => {
        onClose?.();
        setSelectedRows(null);
      }}
      dragEnabled
      //   closeOnOutsideClick
      showCloseButton
      showTitle
      title="Receipt"
      container=".dx-viewport"
      className="receipt-popup-container"
      width={700}
      height={window.innerWidth <= 601 ? "100%" : 800} // Set height based on screen size
      maxWidth="100%"
      maxHeight="100%"
    >
      <Position at="center" my="center" />
      {(isAdmin || isSuperAdmin) &&
        selectedRows?.length === 1 &&
        !isAquariumTickets && (
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
              text: "Messages",
              onClick: () => setShowMessagesHistory(true),
            }}
          />
        )}

      {(isAdmin || isSuperAdmin) &&
        selectedRows?.length === 1 &&
        !isAquariumTickets && (
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
              text: "History",
              onClick: () => setShowAlbumHistory(true),
            }}
          />
        )}

      {!isPhotographer && !isDelivery && (
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{ text: "print", onClick: handlePrint }}
        />
      )}

      {!isPhotographer && !isDelivery && (
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{ text: "print 10*15", onClick: handleSmallSizePrint }}
        />
      )}

      {!isPhotographer && !isDelivery && selectedRows?.length === 1 && (
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={{ text: "Download PDF", onClick: downloadPdf }}
        />
      )}

      <ScrollView width="100%" height="100%">
        <div id="receiptComponent" ref={receiptRef}>
          {selectedRows?.map?.((rowData) => (
            <TicketReceipt
              isPhotographer={isPhotographer}
              selectedRow={rowData}
            />
          ))}
        </div>
      </ScrollView>
    </Popup>
  );
}
