import { useContext } from "react";
import { UserDataContext } from "../context/userContext";

const useUserIdentity = () => {
  const context = useContext(UserDataContext);
  const userData = context?.state?.userData || null;
  const isAdmin = userData?.role === "admin";
  const isSuperAdmin = userData?.role === "superAdmin";
  const isPhotographer = userData?.role === "photographer";
  const isEditor = userData?.role === "editor";
  const isDelivery = userData?.role === "delivery";
  const isPartner = userData?.role === "partner";
  const isAquariumSales = userData?.role === "aquariumSales";
  const isAquariumAdmin = userData?.role === "aquariumAdmin";
  const isAquariumPartner = userData?.role === "aquariumPartner";
  const isEmployee = userData?.role === "employee";

  const isInternalEmployee = !!userData?.empId

  const hasAccessToManageExpenses = userData?.access?.includes?.("access_to_expenses")
  return {
    userData,
    isAdmin,
    isDelivery,
    isPhotographer,
    isSuperAdmin,
    isPartner,
    isAquariumSales,
    isAquariumAdmin,
    isAquariumPartner,
    isEmployee,
    hasAccessToManageExpenses,
    isInternalEmployee,
    isEditor
  };
};

export { useUserIdentity };
