import React, { createContext, useContext, useEffect, useReducer } from "react";
import requester from "../utils/requester";

const UserDataContext = createContext();

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_USER_DATA":
      return { ...state, userData: action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const UserDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, {});
  const value = { state, dispatch };

  useEffect(() => {
    requester()
      .get("/auth/getUserData")
      .then((response) => {
        dispatch({ type: "SET_USER_DATA", payload: response.data?.model });
      })
      .catch((err) => { });
  }, []);

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};

export { UserDataProvider, UserDataContext };
