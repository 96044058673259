import styles from "./layout.module.scss";
import { ReactComponent as Logo } from "../assets/LOGO.svg";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as FacebookLogo } from "../assets/facebook.svg";
import { ReactComponent as InstagramLogo } from "../assets/instagram.svg";
import { ReactComponent as PinterestLogo } from "../assets/pinterest.svg";
import { ReactComponent as TiktokLogo } from "../assets/tiktok.svg";
import contact from "../constants/contact-us.json";
import services from "../constants/services";

const routeWithHiddenHeader = [
  "/admin/add-ticket",
  "/admin/edit-ticket",
  "/admin/add-aquarium-ticket",
];
const Header = () => {
  const { pathname } = useLocation();
  const shouldHideTabs = services.some(
    (service) => `/${service.route}` === pathname
  );

  const shouldHideHeader = routeWithHiddenHeader.includes(pathname);

  if (shouldHideHeader) return null;
  return (
    <header className={styles.header}>
      <div className={`container ${styles.header_wrapper}`}>
        <Link to="/" aria-label="logo">
          <Logo />
        </Link>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
            justifyContent: "flex-end",
            marginRight: "0.5rem",
          }}
        >
          {!shouldHideTabs && (
            <div>
              <a href="/">Home</a>
              <a href="#services">Services</a>
              <a href="#clients">Clients</a>
              <a href="#projects">Projects</a>
            </div>
          )}
          <div>
            <a href={contact.facebook} target="blank">
              <FacebookLogo />
            </a>
            <a href={contact.instagram} target="blank">
              <InstagramLogo />
            </a>
            <a href={contact.pinterest} target="blank">
              <PinterestLogo />
            </a>
            <a href={contact.tiktok} target="blank">
              <TiktokLogo />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
