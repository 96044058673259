import styles from "./layout.module.scss";
import { ReactComponent as PhoneSVG } from "../assets/phone.svg";
import { ReactComponent as EmailSVG } from "../assets/email.svg";
import { ReactComponent as LocationSVG } from "../assets/location.svg";
import { ReactComponent as FacebookLogo } from "../assets/dark-facebook.svg";
import { ReactComponent as InstagramLogo } from "../assets/dark-instagram.svg";
import { ReactComponent as PinterestLogo } from "../assets/dark-pinterest.svg";
import { ReactComponent as TiktokLogo } from "../assets/dark-tiktok.svg";
import coloredLogo from "../assets/colored-logo.png";

import contacts from "../constants/contact-us.json";
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.mainFooter}>
        <div className={`container ${styles.footerContainer}`}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img src={coloredLogo} width="150px" height="90px" />
            <div>
              Since 2001, our Company has been one of the pioneers of
              professional photography & videography
            </div>
            <div style={{ marginTop: "auto" }}>© 2023 Fimtosoft</div>
          </div>
          <div className={styles.contacts}>
            <div className={styles.contactList}>
              <h4 style={{ marginTop: "0.5rem" }}>Contact Us</h4>
              <a style={{ display: "block" }}>Hurghada, Red Sea</a>
              <a
                className={styles.contactLink}
                target="_blank"
                href={`tel:${contacts.phone1}`}
                rel="noreferrer"
              >
                <PhoneSVG />
                &nbsp;
                <span>{contacts.phone1}</span>
              </a>

              <a
                className={styles.contactLink}
                target="_blank"
                href={`tel:${contacts.phone2}`}
                rel="noreferrer"
              >
                <PhoneSVG />
                &nbsp;
                <span>{contacts.phone2}</span>
              </a>

              <a
                className={styles.contactLink}
                target="_blank"
                href={`${contacts.location}`}
                rel="noreferrer"
              >
                <LocationSVG />
                &nbsp;
                <span>Hurghada</span>
              </a>

              <a
                className={styles.contactLink}
                target="_blank"
                href={`mailto:${contacts.email}`}
                rel="noreferrer"
              >
                <EmailSVG />
                &nbsp;
                <span>{contacts.email}</span>
              </a>
            </div>
          </div>
          <div className={styles.contacts}>
            <div className={styles.contactList}>
              <h4 style={{ marginTop: "0.5rem" }}>Follow Us</h4>
              <a href={contacts.facebook} target="blank">
                <FacebookLogo fill="#6b6b84" />
                &nbsp;
                <span>Facebook</span>
              </a>
              <a href={contacts.instagram} target="blank">
                <InstagramLogo width="20px" />
                &nbsp;
                <span>Instagram</span>
              </a>
              <a href={contacts.pinterest} target="blank">
                <PinterestLogo />
                &nbsp;
                <span>Pinterest</span>
              </a>
              <a href={contacts.tiktok} target="blank">
                <TiktokLogo />
                &nbsp;
                <span>Tiktok</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
