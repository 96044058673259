import React from "react";
import { Link } from "react-router-dom";
import services from "../../../constants/services";
import styles from "../styles.module.scss";

export default function ServiceDropDown() {
  return (
    <div className={styles.dropdownContainer}>
      {services.map((service) => (
        <div key={service.route}>
          <Link to={service.route}>{service.name?.toUpperCase?.()}</Link>
        </div>
      ))}
    </div>
  );
}
