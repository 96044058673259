import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";

export const getMessageStatusColor = (status) => {
  const statusColors = {
    queued: "yellow",
    sending: "yellow",
    sent: "grey",
    failed: "red",
    delivered: "blue",
    undelivered: "red",
    receiving: "yellow",
    received: "blue",
    accepted: "blue",
    scheduled: "yellow",
    read: "#50f52c",
    canceled: "red",
  };

  return statusColors[status] || "black";
};

export const getDeviceId = () => {
  const deviceId = localStorage.getItem("deviceId");

  if (!deviceId) {
    const newDeviceId = uuidv4();
    localStorage.setItem("deviceId", newDeviceId);
    return newDeviceId;
  }
  return deviceId;
};

export const getNearestDate = (date1, date2) => {
  const diff1 = Math.abs(date1.diff(moment(), "minutes"));
  const diff2 = Math.abs(date2.diff(moment(), "minutes"));

  if (diff1 < diff2) {
    return date2;
  } else if (diff2 < diff1) {
    return date1;
  } else {
    return date1;
  }
};
export const dataURLtoFile = (dataURL, fileName) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

export const retrieveFileFromLocalStorage = (key) => {
  const fileDataStringified = localStorage.getItem(key);

  if (fileDataStringified) {
    const fileData = JSON.parse(fileDataStringified);
    // Parse the file data back into a File object
    const file = dataURLtoFile(fileData.image, "fileName.jpg");

    return file;
  }

  return null;
};

export const storeFileInLocalStorage = (albumId, file) => {
  const reader = new FileReader();

  reader.onload = function () {
    try {
      const fileData = reader.result;

      // Store the file data in localStorage
      localStorage.setItem(
        albumId,
        JSON.stringify({ image: fileData, date: new Date() })
      );
    } catch (e) {
      console.log("err");
    }
  };

  reader.readAsDataURL(file);
};

export const compressImage = async (imageFile) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    const compressedFile = await imageCompression(imageFile, options);
    // console.log(
    //   "compressedFile instanceof Blob",
    //   compressedFile instanceof Blob
    // ); // true
    // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

    return compressedFile;
  } catch (error) {
    console.log(error);
  }
};
