import React from "react";
import styles from "./styles.module.scss";
import whatsappLogo from "../../assets/whatsapp.png";
import contacts from "../../constants/contact-us.json";

export default function BackgroundLayout({
  background,
  children,
  style = {},
  ...props
}) {
  return (
    <div
      style={{ backgroundImage: `url(${background})`, ...style }}
      className={styles.layoutContainer}
      {...props}
    >
      {children}

      <a
        href={contacts.whatsapp}
        target="_blank"
        className={styles.whatsappBtn}
      >
        <img src={whatsappLogo} />
      </a>
    </div>
  );
}
