import React, { lazy, Suspense } from "react";
import { Router, Route, Switch } from "react-router-dom";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/home";
// import Gallery from "./pages/Gallery";
import history from "./utils/history";
import { ToastContainer } from "react-toastify";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./styles/globals.scss";
import "react-toastify/dist/ReactToastify.css";
import PageNotFound from "./pages/PageNotFound";
import services from "./constants/services";
import Partner from "./pages/partner";
import Login from "./pages/login";
import DownloadReceipt from "./pages/DownloadReceipt";
import * as Sentry from "@sentry/react";

const AdminPanel = lazy(() => import("./pages/AdminPanel"));

Sentry.init({
  dsn: "https://9fcf63c71a825e99af1a8df59f7d3774@o4506757435883520.ingest.sentry.io/4506757437063168",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={10000}
        newestOnTop={false}
        closeOnClick
        hideProgressBar
        pauseOnHover
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <Suspense fallback={<div>Loading...</div>}>
        <Router history={history}>
          <Header />
          <div className="main">
            <Switch>
              <Route path="/" exact render={() => <Home />} />

              {/* <Route path="/code/:code" exact render={() => <Home />} /> */}

              <Route path="/login" exact render={() => <Login />} />

              <Route
                path="/download-receipt/:albumId"
                exact
                render={() => <DownloadReceipt />}
              />

              {/* <Route path="/gallery" exact render={() => <Gallery />} /> */}

              <Route path="/admin" render={() => <AdminPanel />} />
              <Route
                path="/reload"
                render={() => (
                  <button
                    onClick={() => {
                      window.location.reload(true);
                    }}
                  >
                    reload
                  </button>
                )}
              />

              {services.map((service) => (
                <Route
                  path={`/${service.route}/:code?`}
                  exact
                  render={() => <Partner partnerData={service} />}
                  key={service.route}
                />
              ))}

              <Route render={() => <PageNotFound />} />
            </Switch>
          </div>
          <Footer />
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
