import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as OurServicesTitleSVG } from "../../assets/our-services-title.svg";
import ourServices from "../../assets/our-services.png";

export default function Services() {
  return (
    <div id="services" className={styles.sectionContainer}>
      <OurServicesTitleSVG width="15rem" height="5rem" />
      <img src={ourServices} />
    </div>
  );
}
