import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styles from "./styles.module.scss";
import history from "../../utils/history";
import requester from "../../utils/requester";

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  function onSubmit(data) {
    Promise.any([
      requester().post("/auth/login", {
        ...data,
      }),
      requester().post("/auth/photographer-login", {
        ...data,
      }),
    ])
      .then((res) => {
        console.log(res);
        localStorage.setItem(
          "gofimtosoftUserData",
          JSON.stringify(res.data.token)
        );
        history.push({
          pathname: "/admin",
        });
      })
      .catch((err) => {
        toast.error(
          <div style={{ textAlign: "center" }}>
            {err.response?.data?.message || "Failed to login"}
          </div>
        );
      });
  }
  // requester().post('/auth/register', { name: 'admin', username: 'admin', password: 'admin@2021' })
  return (
    <div
      className="black-background"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <form className={styles.home} onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("username", { required: true })}
          placeholder="Username"
          className={`input ${errors.username && "unvalid"}`}
        ></input>
        <input
          {...register("password", { required: true })}
          placeholder="Password"
          type="password"
          className={`input ${errors.password && "unvalid"}`}
        ></input>
        <button className="button" type="submit">
          Login
        </button>
      </form>
    </div>
  );
}
