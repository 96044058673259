import React, { useState } from "react";
import styles from "../styles.module.scss";

import homeBg from "../../../assets/home-bg.png";
import ServiceDropDown from "./ServiceDropDown";
import BackgroundLayout from "../../../components/BackgroundLayout";
import { ReactComponent as ScrollDownSVG } from "../../../assets/scroll-down.svg";

export default function HomeHeader() {
  const [showDropdown, setShowDropdown] = useState(false);
  return (
    <BackgroundLayout id="home" background={homeBg}>
      <div className={styles.headerContainer}>
        <h1>RE-PLAY YOUR MOMENTS</h1>

        <div className={styles.partnerSelectContainer}>
          <button
            className="button animated-button"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            GET YOUR MOMENTS
          </button>
          {showDropdown && <ServiceDropDown />}
        </div>
      </div>
      <div className={styles.scrollDown}>
        <ScrollDownSVG />
        <h2>scroll down</h2>
      </div>
    </BackgroundLayout>
  );
}
