import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as OurProjectsTitleSVG } from "../../assets/our-projects-title.svg";
import { ReactComponent as FimtoArtSVG } from "../../assets/fimto-art.svg";
import { ReactComponent as FimtoFrameSVG } from "../../assets/fimto-frame.svg";

export default function Projects() {
  return (
    <div
      id="projects"
      className={styles.sectionContainer}
      style={{ padding: "3rem 2% 0" }}
    >
      <OurProjectsTitleSVG width="15rem" height="5rem" />

      <div
        className={styles.projectsSection}
      >
        <div className={styles.projectContainer}>
          <FimtoFrameSVG />
          <div>
            Fimto Frame..Specialized in frame, show us your photos and let us
            framed your moments.
          </div>
        </div>

        <div className={styles.projectContainer}>
          <FimtoArtSVG />
          <div>
            Fimto Art.. we are Da vinci’s son’s.. we are not just frame makers..
            we make art.. show us your decor and let us make you the art.
          </div>
        </div>
      </div>
    </div>
  );
}
