import React from "react";
import AlbumLinkForm from "../../components/AlbumLinkForm";
import BackgroundLayout from "../../components/BackgroundLayout";
import Clients from "../home/Clients";
import Projects from "../home/Projects";
import Services from "../home/Services";
import Slug from "../home/Slug";
import styles from "./styles.module.scss";

export default function Partner({ partnerData }) {
  return (
    <div className="black-background">
      <BackgroundLayout
        background={partnerData.background}
        style={{ height: "100vh", backgroundSize: "100%" }}
      >
        <div className={styles.partnerBody}>
          <AlbumLinkForm />
        </div>
      </BackgroundLayout>

      <Services />

      <Clients />

      <Projects />

      <Slug />
    </div>
  );
}
