import React from "react";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";

import ScrollView from "devextreme-react/scroll-view";
import requester from "../utils/requester";
import { toast } from "react-toastify";
import { DataGrid } from "devextreme-react";
import CustomStore from "devextreme/data/custom_store";
import {
  Column,
  HeaderFilter,
  SearchPanel,
  Button,
} from "devextreme-react/data-grid";
import whatsappLogo from "../assets/whatsapp.png";
import { getMessageStatusColor } from "../utils/helpers";

async function onResendReceipt(key, to) {
  try {
    await requester().post(`/albums/send-receipt/${key}`, {
      to,
    });
    toast.info(
      <div style={{ textAlign: "center" }}>Message sent successfully</div>
    );
  } catch (err) {
    toast.error(
      <div style={{ textAlign: "center" }}>Failed to send message</div>
    );
  }
}

export default function MessagesHistory({
  showMessagesHistory,
  setShowMessagesHistory,
  albumId,
}) {
  const getMessages = async () => {
    return await requester()
      .get(`/messages/get-messages/${albumId}`)
      .then((response) => {
        return response.data;
      });
  };
  const dataSource = new CustomStore({
    key: "_id",
    load: () => {
      return getMessages();
    },
  });

  return (
    <Popup
      visible={showMessagesHistory}
      onHiding={() => setShowMessagesHistory(false)}
      dragEnabled
      //   closeOnOutsideClick
      showCloseButton
      showTitle
      title="Receipt"
      container=".dx-viewport"
      width={700}
      maxWidth="90%"
      maxHeight="95%"
    >
      <Position at="center" my="center" />
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: "Back",
          onClick: () => {
            setShowMessagesHistory(false);
          },
        }}
      />
      <ScrollView width="100%" height="100%">
        {
          <DataGrid
            dataSource={dataSource}
            showBorders={true}
            showRowLines={true}
            cellHintEnabled={true}
            wordWrapEnabled={true}
            rowAlternationEnabled={true}
            width="100%"
          >
            <SearchPanel visible={true} all />
            <HeaderFilter visible={true} allowSearch />

            <Column dataField="phoneNumber" alignment={"center"} />
            <Column dataField="status" alignment={"center"} />

            <Column
              dataField="createdAt"
              dataType="date"
              alignment={"center"}
              sortOrder={"asc"}
              allowFiltering={false}
            />
            <Column
              dataField="updatedAt"
              dataType="date"
              alignment={"center"}
              allowFiltering={false}
            />

            <Column type="buttons">
              <Button
                text="Send Receipt"
                // icon="message"
                hint="Send Receipt"
                component={({ data }) => {
                  return (
                    <i
                      className=" dx-icon-message send-receipt-msg"
                      style={{ color: getMessageStatusColor(data.data.status) }}
                      onClick={() =>
                        onResendReceipt(data.data.album, data.data.phoneNumber)
                      }
                    />
                  );
                }}
              />
              <Button
                text="Whatsapp chat"
                hint="Whatsapp chat"
                component={({ data }) => {
                  return (
                    <a
                      href={`https://wa.me/${data?.data?.phoneNumber}`}
                      target="_blank"
                    >
                      <img
                        style={{
                          marginTop: "-10px",
                          width: "18px",
                          height: "18px",
                        }}
                        src={whatsappLogo}
                      />
                    </a>
                  );
                }}
              />
            </Column>
          </DataGrid>
        }
      </ScrollView>
    </Popup>
  );
}
