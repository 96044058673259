import React from "react";
import styles from "./styles.module.scss";

import { ReactComponent as SlugSVG } from "../../assets/slug.svg";

export default function Slug() {
  return (
    <div className={styles.sectionContainer} >
      <SlugSVG width="100%" height="100%" style={{marginBottom:"3rem"}}/>
    </div>
  );
}
