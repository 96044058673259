import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReceiptPopup from "../components/ReceiptPopup";
import requester from "../utils/requester";

export default function DownloadReceipt() {
  const { albumId } = useParams();
  const [albumDetails, setAlbumDetails] = useState(null);
  useEffect(() => {
    requester()
      .get("/albums/download-receipt/" + albumId)
      .then((res) => setAlbumDetails(res.data?.data));
  }, []);

  return <ReceiptPopup selectedRow={albumDetails} setSelectedRow={()=>{}} downloadDirectly={true}/>;
}
